import { ButtonSecondary } from "../ButtonSecondary/ButtonSecondary";
import Skewed from '../Skewed/Skewed';
import './ItemCard.css'

interface Props {
    heading: string,
    media: string, // Renamed from 'image' to 'media' to be more generic
    id: string,
    type: 'image' | 'video' // Added 'type' to specify the media type
}

export const ItemCard = (props: Props) => {
    const handleClick = () => {
        window.location.href = `/project/${props.id}`
    }

    return (
        <div className="item-card__cont">
            <div className="item-card__header">
                <h3 onClick={handleClick}>{props.heading}</h3>
                <ButtonSecondary label="See More" onClick={handleClick}/>
            </div>
            <Skewed>
                {props.type === 'image' ? (
                    <img className="item-card__media" onClick={handleClick} src={require(`../../assets/${props.media}`)} alt={props.heading} />
                ) : (
                    <video className="item-card__media" autoPlay muted loop preload="auto" onClick={handleClick}>
                    <source src={require(`../../assets/${props.media}`)} type="video/mp4" />
                    Your browser does not support the video tag.
                </video>
                )}
            </Skewed>
        </div>
    )
}
