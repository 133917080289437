import "./Icon.css";
// Importing icons
import Git from "../../assets/icons/git.png"
import Github from "../../assets/icons/github.png";
import Nextjs from "../../assets/icons/next.png"
import Axios from "../../assets/icons/axios.png";
import Firebase from "../../assets/icons/firebase.png";
import SQL from "../../assets/icons/sql.png";
import Java from "../../assets/icons/java.png";
import Typescript from "../../assets/icons/typescript.png";
import Node from "../../assets/icons/node.png";
import Expo from "../../assets/icons/expo.png";
import Express from "../../assets/icons/express.png";
import Json from "../../assets/icons/json.png";
import ReactImg from "../../assets/icons/react.png";
import Restapi from "../../assets/icons/restapi.png";

// Define a type for the icons object
type IconMap = {
  [key: string]: string; // This assumes all your icons are strings (image URLs)
};

// Mapping of names to imported images
const icons: IconMap = {
  Git,
  Github,
  Nextjs,
  Axios,
  Firebase,
  Java,
  SQL,
  Typescript,
  Node,
  Expo,
  Express,
  Json,
  react: ReactImg,
  Restapi,
};

type IconProps = {
  name: string;
};

const Icon: React.FC<IconProps> = ({ name }) => {
  const icon = icons[name]; // TypeScript now understands that icon is a string

  if (!icon) {
    console.warn(`Icon with name "${name}" not found.`);
    return null; // Or render a default/fallback icon
  }

  return <img className='tech-icon' src={icon} alt={name} title={name} />;
};

export default Icon;
