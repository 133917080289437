import React, { useState } from 'react';
import './IntroSection.css';
import { ButtonSecondary } from "../ButtonSecondary/ButtonSecondary";

export const IntroSection = () => {
    const [showMore, setShowMore] = useState(false);

    const toggleShowMore = () => {
        setShowMore(!showMore);
    };

    return (
        <div id='intro' className="container intro-section">
            <p>My education at the Copenhagen School of Design and Technology, along with Sorter-Line Study Center, provided a solid foundation in software development, which I’ve been grateful to build on through diverse projects. These experiences, from independent work to collaborative efforts, have helped me develop strong skills in full-stack development, particularly with React, TypeScript, Next.js, and Node.js. I aim to deliver solutions that meet the unique requirements of each project, always considering future adaptability and robustness.</p>

            <div className={`additional-content ${showMore ? 'expanded' : 'collapsed'}`}>

                <p> In team settings, I prioritize quality, reliability, and attention to detail, and I’m honored to have become a dependable contributor in collaborative projects. I strive to integrate seamlessly into teams, supporting shared goals and contributing to project success with a commitment to detail and precision. Likewise, my independent projects have strengthened my ability to navigate complex challenges and create practical solutions, helping me stay adaptable and ready to contribute in various environments.</p>

                <p>With a versatile skill set across programming, agile methodologies, and IT architecture, I’m dedicated to implementing efficient and forward-thinking solutions. Whether in a team or independently, I bring a mindset focused on growth and a sincere commitment to delivering strong, lasting results.</p>
            </div>

            <ButtonSecondary label={showMore ? "Less" : "More"} onClick={toggleShowMore} />
        </div>
    );
};
