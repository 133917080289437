import data from '../assets/data/data.json';

export interface ProjectData {
    "name": string
    "short": string
    "date": string
    "website": string,
    "description": string
    "role": string
    "customer": string
    "tech": string[]
    "repo1": string
    "repo2": string
    "header_media": string
    "img": string[]
}
interface AllProjectsData {
    [key: string]: ProjectData;
}

export const allProjectsData = data as AllProjectsData;