import useScroll from '../../hooks/useScroll';
import { ItemCard } from '../ItemCard/ItemCard'
import './journey.css'
import { allProjectsData } from "../../utils/projectData";

/* interface Props {
    ref: React.MutableRefObject<null>
} */

const Journey = (/* props: Props */) => {

    let backgroundImage1 = require("../../assets/top-line.png");
    let backgroundImage2 = {
        backgroundImage: `url(${require("../../assets/line.png")}`
    }

    if (window.matchMedia('(max-width: 992px)').matches) {
        backgroundImage1 = require("../../assets/top-line-m.png");
        backgroundImage2.backgroundImage = `url(${require("../../assets/line-m.png")}`;
    }


    const determineMediaType = (fileName: string) => {
        const extension = fileName?.split('.').pop()?.toLowerCase() ?? '';
        return ['mp4', 'webm', 'ogv'].includes(extension) ? 'video' : 'image';
    };
    


    return (
        <div id='journey' className='journey main-section'>
            <div className='journey__header'>
                <h3>My Journey</h3>
                <img src={backgroundImage1} />
            </div>

            <div className='journey__content' style={backgroundImage2}>
                {Object.keys(allProjectsData).map(key => {

                    const mediaType = determineMediaType(allProjectsData[key].header_media);

                    return <ItemCard heading={allProjectsData[key].short} media={allProjectsData[key].header_media} id={key} key={key} type={mediaType}/>
                })}
            </div>
        </div>
    )



}

export default Journey