import React from 'react'
import Hero from '../../components/Hero/Hero'
import info from '../../assets/info.json'
import TechStack from '../../components/TechStack/TechStack'
import { AboutIntroduction } from '../../components/AboutIntroduction/AboutIntroduction'
import ScrollBar from '../../components/ScrollBar/ScrollBar';
import ScrollButton from '../../components/ScrollButton/ScrollButton';
import { Skills } from '../../components/Skills/Skills'

const About = () => {
  return (
    <>
          <ScrollBar />
      <ScrollButton />
      
      <div className='page-cont'>

    <Hero
  firstLine="My name is"
  secondLine={info.fullName}
  thirdLine="& I'm a Software Developer"
  hashLink='#about-intro'
/>

<AboutIntroduction/>

<TechStack />

</div>

{/* <Skills/> */}
</>

  )
}

export default About