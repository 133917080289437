import React, { useState } from 'react';
import ScrollBar from "../../components/ScrollBar/ScrollBar";
import ScrollButton from "../../components/ScrollButton/ScrollButton";
import './Project.css';
import { useLocation } from "react-router";
import { allProjectsData, ProjectData } from "../../utils/projectData";

const Project = () => {
    const location = useLocation();
    const projectId = location.pathname.split('/').pop() || '1';
    const projectData = allProjectsData[projectId] as ProjectData;

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedMedia, setSelectedMedia] = useState('');
    const [mediaType, setMediaType] = useState<'image' | 'video'>('image');

    const handleMediaClick = (mediaSrc: string, type: 'image' | 'video') => {
        setSelectedMedia(mediaSrc);
        setMediaType(type);
        setIsModalOpen(true);
    };

    return (
        <>
            <ScrollBar />
            <ScrollButton />

            <div className='page-cont'>
                <div id="header" className="project-header main-section">
                    <h3>{projectData.name}</h3>
                    <h6>{projectData.date}</h6>
                    <div className="project-header--image-cont">
                        {projectData.img[0].endsWith('.mp4') ? (
                            <video autoPlay muted loop preload="auto" className="project-header--video">
                                <source src={require(`../../assets/data/${projectId}/${projectData.img[0]}`)} type="video/mp4" />
                                Your browser does not support the video tag.
                            </video>
                        ) : (
                            <img src={require(`../../assets/data/${projectId}/${projectData.img[0]}`)} alt=""/>
                        )}
                    </div>
                </div>
                <p className="project-description">{projectData.description}</p>
                <div id="details" className="project-details main-section">
                    <div>
                        <p className="project-details--header">Role:</p>
                        <p>{projectData.role}</p>
                    </div>
                    <div>
                        <p className="project-details--header">Client:</p>
                        <p>{projectData.customer}</p>
                    </div>
                </div>
                <div className="project--image-cont">
                    {projectData.img.slice(1).map((media, index) => {
                        const mediaSrc = require(`../../assets/data/${projectId}/${media}`);
                        if (media.endsWith('.mp4')) {
                            return (
                                <video key={index} className="project--video" autoPlay muted loop preload="auto" onClick={() => handleMediaClick(mediaSrc, 'video')}>
                                    <source src={mediaSrc} type="video/mp4" />
                                    Your browser does not support the video tag.
                                </video>
                            );
                        } else {
                            return (<img key={index} src={mediaSrc} alt="" style={{ cursor: 'pointer' }} onClick={() => handleMediaClick(mediaSrc, 'image')} />);
                        }
                    })}
                </div>
                {isModalOpen && (
                    <div className="modal" onClick={() => setIsModalOpen(false)}>
                        {mediaType === 'video' ? (
                            <video controls src={selectedMedia} className="modal-content"></video>
                        ) : (
                            <img src={selectedMedia} alt="" className="modal-content" />
                        )}
                        <span className="close-modal" onClick={() => setIsModalOpen(false)}>&times;</span>
                    </div>
                )}
            </div >
        </>
    )
}

export default Project;
